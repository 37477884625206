var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('t-modal',{model:{value:(_vm.showDetails),callback:function ($$v) {_vm.showDetails=$$v},expression:"showDetails"}},[_c('div',{staticClass:"p-4"},[_c('h4',{staticClass:"text-sm font-semibold text-gray-800"},[_vm._v(" Update task progress ")]),_c('div',{staticClass:"flex justify-end items-center"},[_c('div',{staticClass:"flex flex-col items-end space-y-1"},[_c('span',{staticClass:"text-xs px-3 py-2 text-white bg-accent rounded font-medium text-center capitalize"},[_vm._v(_vm._s(_vm.selectedTask.priority))]),_c('span',{staticClass:"text-xs font-semibold text-accent"},[_vm._v("Due "+_vm._s(_vm.selectedTask.dueDate))]),_c('span',{staticClass:"text-xs font-semibold text-gray-600"},[_vm._v("Created on: "+_vm._s(_vm.selectedTask.startDate))])])]),_c('div',[_c('p',{staticClass:"text-sm font-semibold capitalize text-gray-700"},[_vm._v(" "+_vm._s(_vm.selectedTask.title)+" ")]),_c('p',{staticClass:"text-sm mt-4 font-normal text-gray-500"},[_vm._v(" "+_vm._s(_vm.selectedTask.description)+" ")])]),_c('div',{staticClass:"flex mt-5 w-full justify-between items-center"},[_c('div',{staticClass:"w-9/12"},[_c('t-select',{staticClass:"w-9/12",attrs:{"options":[
              'Pending',
              'Started',
              'Completed',
              'Cancelled',
              'Declined'
            ]},model:{value:(_vm.selectedTask.status),callback:function ($$v) {_vm.$set(_vm.selectedTask, "status", $$v)},expression:"selectedTask.status"}})],1),_c('div',[_c('t-button',{staticClass:"flex space-x-3 items-center",on:{"click":_vm.updateTask}},[_c('span',[_vm._v("Update")]),(_vm.updatingTask)?_c('spinner'):_vm._e()],1)],1)])])]),_c('page-title',[_vm._v("Tasks")]),_c('page-subtitle',[_vm._v("Here's your task")]),_c('div',{staticClass:"mt-5"},[_vm._m(0),_c('div',{staticClass:"bg-white p-5 rounded"},[_c('div',{staticClass:"mb-5 bg-white"},[_vm._m(1),_c('div',{staticClass:"task-list grid grid-cols-1 mt-5"},[_c('div',{staticClass:"flex flex-col w-full"},[_c('div',{staticClass:"overflow-x-auto"},[_c('table',{staticClass:"table-auto min-h-full min-w-full"},[_vm._m(2),_c('tbody',_vm._l((_vm.tasks),function(task){return _c('tr',{key:task._id,staticClass:"text-sm cursor-pointer hover:border-l-2 hover:border-primary hover:bg-primary-faded",on:{"click":function($event){return _vm.showUpdateModal(task)}}},[_c('td',{staticClass:"px-3 capitalize py-4"},[_vm._v(_vm._s(task.title))]),_c('td',{staticClass:"px-3 py-4"},[_vm._v(_vm._s(task.startDate))]),_c('td',{staticClass:"px-3 py-4"},[_vm._v(_vm._s(task.dueDate))]),_c('td',{staticClass:"px-3 py-4"},[_c('span',{staticClass:"px-2 py-1 rounded text-white",class:task.priority === 'normal'
                            ? 'bg-yellow-400 text-white'
                            : task.priority === 'urgent'
                            ? 'bg-red-600'
                            : 'bg-gray-400'},[_vm._v(_vm._s(task.priority))])]),_c('td',{staticClass:"px-3 py-4"},[_vm._v(_vm._s(task.status))])])}),0)])])])])])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex rounded space-x-2 items-center justify-between mb-5"},[_c('div',{staticClass:"search-container border focus-within:ring-2 ring-primary border-gray-100 rounded pl-4 flex items-center w-full"},[_c('img',{staticClass:"w-5 h-5",attrs:{"src":require("../../assets/svgs/search.svg"),"alt":"search"}}),_c('input',{staticClass:"border-none shadow-none bg-transparent w-full focus:outline-none focus:border-none focus:ring-0",attrs:{"type":"text","placeholder":"Search by name, email..."}})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tabs flex md:flex-row flex-col md:space-x-3 text-xs md:text-sm font-semibold border-b border-gray-200"},[_c('span',{staticClass:"py-3 px-5 cursor-pointer bg-primary text-white rounded-tl"},[_vm._v("All Status")]),_c('span',{staticClass:"py-3 px-5 cursor-pointer"},[_vm._v("Not Started")]),_c('span',{staticClass:"py-3 px-5 cursor-pointer"},[_vm._v("Started")]),_c('span',{staticClass:"py-3 px-5 cursor-pointer"},[_vm._v("Completed")]),_c('span',{staticClass:"py-3 px-5 cursor-pointer"},[_vm._v("Cancelled")]),_c('span',{staticClass:"py-3 px-5 cursor-pointer"},[_vm._v("Declined")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',{staticClass:"bg-primary-faded border-b-2 border-primary text-left font-semibold text-sm text-black"},[_c('tr',[_c('th',{staticClass:"py-3 px-3"},[_vm._v("Title")]),_c('th',{staticClass:"py-3 px-3"},[_vm._v("Date Created")]),_c('th',{staticClass:"py-3 px-3"},[_vm._v("Due Date")]),_c('th',{staticClass:"py-3 px-3"},[_vm._v("Priority")]),_c('th',{staticClass:"py-3 px-3"},[_vm._v("Status")])])])}]

export { render, staticRenderFns }