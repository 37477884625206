<template>
  <div>
    <t-modal v-model="showDetails">
      <div class="p-4">
        <h4 class="text-sm font-semibold text-gray-800">
          Update task progress
        </h4>
        <div class="flex justify-end items-center">
          <div class="flex flex-col items-end space-y-1">
            <span
              class="text-xs px-3 py-2 text-white bg-accent rounded font-medium text-center capitalize"
              >{{ selectedTask.priority }}</span
            >
            <span class="text-xs font-semibold text-accent"
              >Due {{ selectedTask.dueDate }}</span
            >
            <span class="text-xs font-semibold text-gray-600"
              >Created on: {{ selectedTask.startDate }}</span
            >
          </div>
        </div>
        <div>
          <p class="text-sm font-semibold capitalize text-gray-700">
            {{ selectedTask.title }}
          </p>
          <p class="text-sm mt-4 font-normal text-gray-500">
            {{ selectedTask.description }}
          </p>
        </div>
        <div class="flex mt-5 w-full justify-between items-center">
          <div class="w-9/12">
            <t-select
              v-model="selectedTask.status"
              class="w-9/12"
              :options="[
                'Pending',
                'Started',
                'Completed',
                'Cancelled',
                'Declined'
              ]"
            >
            </t-select>
          </div>
          <div>
            <t-button @click="updateTask" class="flex space-x-3 items-center"
              ><span>Update</span>
              <spinner v-if="updatingTask"></spinner>
            </t-button>
          </div>
        </div>
      </div>
    </t-modal>
    <page-title>Tasks</page-title>
    <page-subtitle>Here's your task</page-subtitle>
    <div class="mt-5">
      <div class="flex rounded space-x-2 items-center justify-between mb-5">
        <div
          class="search-container border focus-within:ring-2 ring-primary border-gray-100 rounded pl-4 flex items-center w-full"
        >
          <img
            class="w-5 h-5"
            src="../../assets/svgs/search.svg"
            alt="search"
          />
          <input
            class="border-none shadow-none bg-transparent w-full focus:outline-none focus:border-none focus:ring-0"
            type="text"
            placeholder="Search by name, email..."
          />
        </div>
      </div>
      <div class="bg-white p-5 rounded">
        <div class="mb-5 bg-white">
          <div
            class="tabs flex md:flex-row flex-col md:space-x-3 text-xs md:text-sm font-semibold border-b border-gray-200"
          >
            <span
              class="py-3 px-5 cursor-pointer bg-primary text-white rounded-tl"
              >All Status</span
            >
            <span class="py-3 px-5 cursor-pointer">Not Started</span>
            <span class="py-3 px-5 cursor-pointer">Started</span>
            <span class="py-3 px-5 cursor-pointer">Completed</span>
            <span class="py-3 px-5 cursor-pointer">Cancelled</span>
            <span class="py-3 px-5 cursor-pointer">Declined</span>
          </div>
          <div class="task-list grid grid-cols-1 mt-5">
            <div class="flex flex-col w-full">
              <div class="overflow-x-auto">
                <table class="table-auto min-h-full min-w-full">
                  <thead
                    class="bg-primary-faded border-b-2 border-primary text-left font-semibold text-sm text-black"
                  >
                    <tr>
                      <th class="py-3 px-3">Title</th>
                      <th class="py-3 px-3">Date Created</th>
                      <th class="py-3 px-3">Due Date</th>
                      <th class="py-3 px-3">Priority</th>
                      <th class="py-3 px-3">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="task in tasks"
                      :key="task._id"
                      @click="showUpdateModal(task)"
                      class="text-sm cursor-pointer hover:border-l-2 hover:border-primary hover:bg-primary-faded"
                    >
                      <td class="px-3 capitalize py-4">{{ task.title }}</td>
                      <td class="px-3 py-4">{{ task.startDate }}</td>
                      <td class="px-3 py-4">{{ task.dueDate }}</td>
                      <td class="px-3 py-4">
                        <span
                          class="px-2 py-1 rounded text-white"
                          :class="
                            task.priority === 'normal'
                              ? 'bg-yellow-400 text-white'
                              : task.priority === 'urgent'
                              ? 'bg-red-600'
                              : 'bg-gray-400'
                          "
                          >{{ task.priority }}</span
                        >
                      </td>
                      <td class="px-3 py-4">{{ task.status }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageSubtitle from '../../components/Typography/PageSubtitle.vue'
import PageTitle from '../../components/Typography/PageTitle.vue'
import taskApi from '../../api/tasks'
import Spinner from '../../components/Utils/Spinner'

export default {
  name: 'TaskList',
  components: { PageTitle, PageSubtitle, Spinner },
  data: () => ({
    showDetails: false,
    tasks: [],
    selectedTask: {},
    updatingTask: false
  }),
  created() {
    this.getTasks()
  },
  methods: {
    async updateTask() {
      this.updatingTask = true

      // pending', 'started', 'completed', 'cancelled', 'declined'

      const res = await taskApi
        .tasks()
        .update(
          { taskId: this.selectedTask._id },
          this.selectedTask.status.toLowerCase()
        )

      if (res.error) {
        this.$store.commit('alert', {
          error: true,
          status: true,
          message: res.errorMessage
        })
        this.updatingTask = false
        return
      }
      this.$store.commit('alert', {
        error: false,
        status: true,
        message: res.message
      })

      this.getTasks()
      this.updatingTask = false
      this.showDetails = false
    },

    async getTasks() {
      const res = await taskApi.tasks().getAll()
      this.tasks = res.data.data
      console.log(res.data.data)
    },
    showUpdateModal(n) {
      this.selectedTask = n
      this.showDetails = true
    }
  }
}
</script>

<style></style>
